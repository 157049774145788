import * as React from "react"

import FormStepperProvider from "../components/FormStepper/FormStepperProvider"
import ContactStep from "../components/GeneralFormSteps/ContactStep"
import ShippingStep from "../components/GeneralFormSteps/ShippingStep"
import Header from "../components/Header"
import Container from "../components/Container"
import images from "../images"
import GeneralStep from "../components/HoodiesForm/GeneralStep"
import FrontSideStep from "../components/HoodiesForm/FrontSideStep"
import BackSideStep from "../components/HoodiesForm/BackSideStep"
import ArmStep from "../components/HoodiesForm/ArmsStep"
import api from "../service/api"
import { navigate } from "gatsby"

import { FaWhatsapp } from "@react-icons/all-files/fa/FaWhatsapp"
import { FaInstagram } from "@react-icons/all-files/fa/FaInstagram"

export default function Hoodies() {
  async function onSubmit(submitValues) {
    try {
      await api.createHoodiesOrder(submitValues)
      navigate("/confirmation")
    } catch (e) {
      console.warn({ e })
    }
  }

  return (
    <div className="antialiased text-gray-900">
      <Header />
      <div className="flex flex-1">
        <Container>
          <FormStepperProvider
            onSubmit={onSubmit}
            name="Individualisierte Hoodies"
            path="/formular"
            steps={[
              {
                path: "general",
                label: "Allgemein",
                component: GeneralStep,
              },
              {
                path: "front",
                label: "Vorderseite",
                component: FrontSideStep,
              },
              {
                path: "back",
                label: "Rückseite",
                component: BackSideStep,
              },
              { path: "arms", label: "Arme", component: ArmStep },
              {
                path: "contact",
                label: "Privatadresse",
                component: ContactStep,
              },
              {
                path: "shipping",
                label: "Lieferadresse",
                component: ShippingStep,
              },
            ]}
          />
        </Container>
        <div className="hidden lg:flex h-screen w-4/12 bg-indigo-600">
          <div className="fixed h-full w-4/12">
            <img
              alt="Beispiel eines Swissbadge Pullovers"
              className="h-full w-full object-cover"
              src={images.hoodiesSide}
            />
            <div className="absolute left-0 right-0 bottom-0 p-8 justify-center flex flex-1">
              <button
                type="button"
                onClick={() =>
                  window.open(
                    "https://api.whatsapp.com/send?phone=0041782351960"
                  )
                }
              >
                <FaWhatsapp className="text-white" size="32" />
              </button>
              <button
                type="button"
                className="ml-4"
                onClick={() =>
                  window.open("https://www.instagram.com/swissbadge/")
                }
              >
                <FaInstagram className="text-white" size="32" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
