import * as React from "react"

function Container({ children }) {
  return (
    <div className="min-h-screen flex flex-1 flex-col max-w-xl mx-auto pt-24 pb-12 md:max-w-4xl px-6">
      {children}
    </div>
  )
}

export default Container
