import * as React from "react"
import { useForm, FormProvider } from "react-hook-form"
import InputField from "../InputField"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"

import { useFormStepperContext } from "../FormStepper/FormStepperProvider"
import Button from "../Button"

const phoneRegEx = /(\b(0041|0)|\B\+41)(\s?\(0\))?(\s)?[1-9]{2}(\s)?[0-9]{3}(\s)?[0-9]{2}(\s)?[0-9]{2}\b/

const schema = yup.object().shape({
  birthday: yup.string().required("Darf nicht leer sein"),
  firstName: yup.string().required("Darf nicht leer sein"),
  lastName: yup.string().required("Darf nicht leer sein"),
  address: yup.string().required("Darf nicht leer sein"),
  zip: yup.string().required("Darf nicht leer sein"),
  city: yup.string().required("Darf nicht leer sein"),
  email: yup
    .string()
    .email("Bitte gib eine gültige Email Adresse ein")
    .required("Darf nicht leer sein"),
  phone: yup
    .string()
    .required("Darf nicht leer sein")
    .matches(phoneRegEx, "Bitte gib eine gültige Telefonnummer ein"),
})

export default function ContactStep({ values }) {
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: values || {},
  })

  const getMaxDate = () => {
    const date = new Date()
    date.setFullYear(date.getFullYear() - 16)
    return date.toISOString().split("T")[0]
  }

  const getMinDate = () => {
    const date = new Date()
    date.setFullYear(date.getFullYear() - 99)
    return date.toISOString().split("T")[0]
  }

  const { setValues, next, previous } = useFormStepperContext()

  const onSubmit = async data => {
    setValues(data)
    next()
  }

  function renderAddressForm() {
    return (
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="grid grid-cols-1 gap-4">
          <InputField name="firstName" label="Vorname" fullWidth />

          <InputField name="lastName" label="Nachname" fullWidth />

          <InputField name="address" label="Strasse & Nr." fullWidth />

          <InputField name="zip" label="PLZ" />

          <InputField name="city" label="Ort" />

          <InputField
            placeholder="max.muster@email.ch"
            name="email"
            type="email"
            label="Email"
            fullWidth
          />
          <InputField
            placeholder="+41 79 123 45 67"
            name="phone"
            type="tel"
            label="Mobile"
            fullWidth
          />

          <InputField
            name="birthday"
            label="Geburtstag"
            type="date"
            min={getMinDate()}
            max={getMaxDate()}
          />

          <InputField name="promoCode" label="Promo-Code" fullWidth />

          <div className="flex justify-between mt-8">
            <Button
              type="button"
              className="justify-self-end"
              label="Zurück"
              variant="text"
              onClick={previous}
            />

            <Button type="submit" className="justify-self-end" label="Weiter" />
          </div>
        </div>
      </form>
    )
  }

  return <FormProvider {...methods}>{renderAddressForm()}</FormProvider>
}
