import * as React from "react"
import { useFieldArray, useFormContext } from "react-hook-form"
import images from "../images"
import { FiPlus } from "@react-icons/all-files/fi/FiPlus"
import { FiTrash2 } from "@react-icons/all-files/fi/FiTrash2"

import clsx from "clsx"
import FileInputField from "./FileInputField"
import RadioGroupField from "./RadioGroupField"
import FieldLabel from "./FieldLabel"
import TextAreaField from "./TextAreaField"

export const BACK_POSITIONS = [
  {
    image: images.backOutline,
    positions: {
      BACK_HOOD: {
        style: { top: "10%" },
        label: "Kaputze",
        className: "flex flex-1 justify-center left-0 right-0",
      },
      BACK_TOP_LEFT: {
        style: { top: "35%", left: "25%" },
        label: "Oben links",
      },
      BACK_TOP_RIGHT: {
        style: { top: "35%", right: "25%" },
        label: "Oben rechts",
      },
      BACK_CENTER: {
        style: { top: "57%" },
        label: "Mitte",
        className: "flex flex-1 justify-center left-0 right-0",
      },
      BACK_BOTTOM_LEFT: {
        style: { top: "80%", left: "25%" },
        label: "Unten links",
      },
      BACK_BOTTOM_RIGHT: {
        style: { top: "80%", right: "25%" },
        label: "Unten rechts",
      },
    },
  },
]

export const FRONT_POSITIONS = [
  {
    image: images.frontOutline,
    positions: {
      FRONT_TOP_LEFT: {
        style: { top: "40%", right: "28%" },
        label: "Brust links",
      },
      FRONT_TOP_RIGHT: {
        style: { top: "40%", left: "28%" },
        label: "Brust rechts",
      },
      FRONT_CENTER: {
        style: { top: "52%" },
        label: "Mitte",
        className: "flex flex-1 justify-center left-0 right-0",
      },
      FRONT_CENTER_LEFT: {
        style: { top: "60%", right: "22%" },
        label: "Mitte links",
      },
      FRONT_CENTER_RIGHT: {
        style: { top: "60%", left: "22%" },
        label: "Mitte rechts",
      },
      FRONT_BOTTOM_LEFT: {
        style: { top: "77%", right: "30%" },
        label: "Unten links",
      },
      FRONT_BOTTOM_RIGHT: {
        style: { top: "77%", left: "30%" },
        label: "Unten rechts",
      },
    },
  },
]

export const ARM_POSITIONS = [
  {
    image: images.frontOutline,
    positions: {
      ARM_TOP_LEFT: {
        style: { top: "50%", left: "5%" },
        label: "Rechter Arm oben",
      },
      ARM_TOP_RIGHT: {
        style: { top: "50%", right: "5%" },
        label: "Linker Arm oben",
      },
      ARM_BOTTOM_LEFT: {
        style: { top: "75%", left: "2%" },
        label: "Rechter Arm unten",
      },
      ARM_BOTTOM_RIGHT: {
        style: { top: "75%", right: "2%" },
        label: "Linker Arm unten",
      },
    },
  },
]

function PositionSelection({
  name,
  label,
  information,
  views,
  filteredPositions = [],
}) {
  const { fields, append, remove } = useFieldArray({
    name: name,
  })

  const { register } = useFormContext()

  const flattenedPositions = React.useMemo(
    () => views.reduce((acc, cur) => ({ ...acc, ...cur.positions }), {}),
    [views]
  )

  React.useEffect(() => {
    const invalidPosition = fields.findIndex(field =>
      filteredPositions.includes(field.position)
    )
    if (invalidPosition >= 0) {
      remove(invalidPosition)
    }
  }, [filteredPositions, fields, remove])

  return (
    <div>
      <FieldLabel label={label} information={information} />
      <div className="mt-2 flex flex-col space-y-4">
        <div className="flex justify-center space-x-4">
          {views.map(({ image, positions }) => (
            <div
              key={image}
              className="mt-2 relative justify-self-start self-center"
            >
              <img
                src={image}
                style={{ width: 250 }}
                alt="Umriss des Pullovers"
              />
              {Object.entries(positions).map(([position, positionConfig]) => {
                if (
                  (!positionConfig.style && !positionConfig.className) ||
                  filteredPositions.includes(position)
                ) {
                  return null
                }

                const fieldIndex = fields.findIndex(
                  field => field.position === position
                )
                const isSelected = fieldIndex >= 0

                return (
                  <div
                    key={position}
                    className={clsx("absolute", positionConfig.className)}
                    style={positionConfig.style}
                  >
                    <button
                      type="button"
                      onClick={() => {
                        isSelected
                          ? remove(fieldIndex)
                          : append({ position, material: "STICK" })
                      }}
                      className={clsx(
                        "left rounded-full outline-none w-8 h-8 flex items-center justify-center",
                        isSelected ? "bg-pink-600" : "bg-indigo-600"
                      )}
                    >
                      {isSelected ? (
                        <span className="text-white text-xs font-bold">
                          {fieldIndex + 1}
                        </span>
                      ) : (
                        <FiPlus color="white" strokeWidth="3" size="16" />
                      )}
                    </button>
                  </div>
                )
              })}
            </div>
          ))}
        </div>

        {fields.map((field, index) => (
          <div
            key={field.id}
            className="border-indigo-100 border-2 p-4 space-y-4"
          >
            <div className="flex flex-1 justify-between">
              <span className="text-lg font-bold">
                {`${index + 1} – ${flattenedPositions[field.position]?.label}`}
              </span>
              <button onClick={() => remove(index)} type="button">
                <FiTrash2 size="20" />
              </button>
            </div>
            <RadioGroupField
              name={`${name}[${index}].material`}
              label="Veredelungsverfahren"
              key={`${field.id}-material`}
              defaultValue={field.material}
              options={[
                {
                  label: "Stick",
                  value: "STICK",
                },
                {
                  label: "Druck",
                  value: "DRUCK",
                },
              ]}
            />
            <FileInputField
              information="Lade entweder eine Design-Datei hoch oder schreibe unten was du dir vorstellst"
              name={`${name}[${index}].file`}
              key={`${field.id}-file`}
              label="Design"
              accept="image/*"
              defaultValue={field.file}
            />
            <TextAreaField
              placeholder="z.B. Schriftzug, Velcro-Klett"
              key={`${field.id}-note`}
              name={`${name}[${index}].note`}
              label="Beschreibung"
            />
            <input
              type="text"
              ref={register}
              name={`${name}[${index}].position`}
              className="hidden"
              defaultValue={field.position}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default PositionSelection
