import * as React from "react"
import { useFormContext } from "react-hook-form"
import { useDropzone } from "react-dropzone"
import FieldError from "./FieldError"
import FieldLabel from "./FieldLabel"
import _get from 'lodash/get';

const ERRORS = {
  "file-too-large": "Datei darf maximal {{maxSize}}MB gross sein",
  "file-invalid-type": "Dieses Dateiformat wird nicht akzeptiert",
}

const FileInputField = React.forwardRef((props, ref) => {
  const {
    label,
    name,
    className,
    accept,
    multiple,
    maxSize,
    information,
    defaultValue,
    fullWidth,
    ...restProps
  } = props
  const {
    errors,
    setValue,
    register,
    unregister,
    watch,
    setError,
  } = useFormContext()
  const error = _get(errors, name)

  const files = watch(name)

  React.useEffect(() => {
    if (defaultValue?.length > 0) {
      setValue(name, defaultValue)
    }
  }, [defaultValue, setValue, name])

  const onDropAccepted = React.useCallback(
    droppedFiles => {
      setValue(name, droppedFiles, { shouldValidate: true })
    },
    [setValue, name]
  )

  const onDropRejected = React.useCallback(
    rejectedProps => {
      let errorMessage =
        ERRORS[rejectedProps[0]?.errors?.[0]?.code] || "Unbekannter Fehler"

      errorMessage = errorMessage.replace(
        "{{maxSize}}",
        Math.round(maxSize / 1048576)
      )

      setError(name, {
        type: "manual",
        message: errorMessage,
      })
    },
    [setError, name, maxSize]
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDropAccepted,
    onDropRejected,
    accept,
    multiple,
    maxSize,
    ...restProps,
  })

  React.useEffect(() => {
    register(name)
    return () => {
      unregister(name)
    }
  }, [register, unregister, name])

  return (
    <div>
      <FieldLabel {...{ label, information }} />

      <div {...getRootProps()}>
        <input
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id={name}
          {...getInputProps()}
        />
        <div
          className={
            "mt-2 w-full p-2 border border-dashed border-indigo-600 " +
            (isDragActive ? "bg-indigo-100" : "bg-indigo-50")
          }
        >
          <p className="text-center my-2 text-xs">Datei hier reinziehen...</p>
          {!!files?.length && (
            <div className="grid gap-1 mt-2">
              {files.map(file => {
                return (
                  <div key={file.name}>
                    <img
                      src={URL.createObjectURL(file)}
                      alt={file.name}
                      style={{ width: "auto", height: "100px" }}
                    />
                  </div>
                )
              })}
            </div>
          )}
        </div>
      </div>
      <FieldError {...{ error }} />
    </div>
  )
})

FileInputField.defaultProps = {
  maxSize: 3145728, // 2MB
  accept: "image/*,application/pdf",
}

export default FileInputField
