import * as React from "react"
import { useFormContext } from "react-hook-form"
import clsx from "clsx"
import FieldError from "./FieldError"
import FieldLabel from "./FieldLabel"
import get from "lodash/get"

const InputField = React.forwardRef((props, ref) => {
  const {
    label,
    type,
    placeholder,
    name,
    fullWidth,
    inputClassName,
    className,
    leading,
    information,
    ...restProps
  } = props
  const { register, errors } = useFormContext()

  const error = get(errors, name)

  return (
    <label className={clsx("block", className)}>
      <FieldLabel {...{ label, information }} />
      <div className="mt-1 relative">
        {leading && (
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <span className="">{leading}</span>
          </div>
        )}
        <input
          placeholder={placeholder || ""}
          name={name}
          type={type || "text"}
          className={clsx(
            `mt-1 block ${fullWidth ? "w-full" : ""}`,
            inputClassName
          )}
          ref={register}
          {...restProps}
        />
      </div>
      <FieldError {...{ error }} />
    </label>
  )
})

export default InputField
