import * as React from "react"

function FieldError({ error }) {
  if (error?.message) {
    return <span className="text-xs text-red-500">{error.message}</span>
  }
  return null
}

export default FieldError
