import * as React from "react"
import clsx from "clsx"

function FieldLabel({ label, information, className }) {
  if (label) {
    return (
      <div>
        <div className={clsx("text-gray-700 font-semibold", className)}>
          {label}
        </div>
        {information && (
          <div className="text-sm mb-2 text-gray-500">
            {typeof information === "function" ? information() : information}
          </div>
        )}
      </div>
    )
  }
  return null
}

export default FieldLabel
