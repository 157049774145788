import * as React from "react"
import LoadingIndicator from "./LoadingIndicator"

function LoadingOverlay() {
  return (
    <div className="z-50 bg-opacity-90 fixed inset-0 bg-indigo-100 flex flex-1 items-center justify-center">
      <div className="flex flex-col items-center">
        <span className="mb-6 text-xl font-semibold">
          Die Anfrage wird bearbeitet
        </span>
        <LoadingIndicator />
      </div>
    </div>
  )
}

export default LoadingOverlay
