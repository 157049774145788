import * as React from "react"
import { useFormContext } from "react-hook-form"
import FieldError from "./FieldError"
import FieldLabel from "./FieldLabel"
import _get from "lodash/get"

const TextAreaField = React.forwardRef((props, ref) => {
  const { label, placeholder, name, fullWidth = true, ...restProps } = props
  const { register, errors } = useFormContext()
  const error = _get(errors,name)

  return (
    <label className="block">
      <FieldLabel {...{ label }} />
      <textarea
        placeholder={placeholder || ""}
        name={name}
        className={`mt-1 block ${fullWidth ? "w-full" : ""}`}
        ref={register()}
        rows="4"
        cols="50"
        {...restProps}
      />
      <FieldError {...{ error }} />
    </label>
  )
})

export default TextAreaField
