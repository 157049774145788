import * as React from "react"
import { useFormContext } from "react-hook-form"
import FieldError from "./FieldError"
import FieldLabel from "./FieldLabel"
import orderBy from "lodash/orderBy"

const SelectField = React.forwardRef((props, ref) => {
  const { label, name, fullWidth, options, information, order } = props
  const { register, errors } = useFormContext()
  const error = errors?.[name]

  const orderedOptions = React.useMemo(
    () => (order ? orderBy(options, ["label"], ["asc"]) : options),
    [options, order]
  )

  return (
    <label className="block">
      <FieldLabel {...{ label, information }} />
      <select
        name={name}
        className={`mt-1 block ${fullWidth ? "w-full" : ""}`}
        ref={register()}
      >
        {orderedOptions.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <FieldError {...{ error }} />
    </label>
  )
})

SelectField.defaultProps = {
  order: false,
}

export default SelectField
