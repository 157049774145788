import * as React from "react"
import clsx from "clsx"

function Button({ label, className, variant, ...restProps }) {
  return (
    <button
      className={clsx(
        "w-32 border border-transparent py-2 px-5 rounded-none text-center font-medium",
        variant === "filled"
          ? "text-white bg-indigo-600 hover:bg-indigo-800"
          : "text-indigo-600 hover:text-indigo-800",
        className
      )}
      {...restProps}
    >
      {label}
    </button>
  )
}

Button.defaultProps = {
  variant: "filled",
}

export default Button
