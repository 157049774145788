import * as React from "react"
import { Logo } from "../assets"

function Header() {
  return (
    <div
      className={`z-50 fixed w-full py-3 text-white bg-white font-semibold text-xl px-6 flex items-center justify-between bg-opacity-100`}
    >
      {/* <Link to="/"> */}
      <Logo fill="black" width="70" />
      {/* </Link> */}

      <button
        type="button"
        className="text-sm font-semibold text-indigo-600"
        onClick={() => window.open("https://swissbadge.com")}
      >
        Zur Homepage
      </button>
    </div>
  )
}

export default Header
