import * as React from "react"
import { useFormContext } from "react-hook-form"
import clsx from "clsx"
import FieldError from "./FieldError"
import FieldLabel from "./FieldLabel"

const RadioGroupField = React.forwardRef((props, ref) => {
  const { label, name, options, className, defaultValue } = props
  const { errors, register, setValue } = useFormContext()
  const error = errors?.[name]

  React.useEffect(() => {
    if (defaultValue) {
      setValue(name, defaultValue)
    }
  }, [defaultValue, setValue, name])

  return (
    <label className="block">
      <FieldLabel {...{ label }} />
      <fieldset className={clsx("mt-1 flex space-x-8", className)}>
        {options.map(option => (
          <div
            key={option.value}
            className={"inline-flex items-center space-x-2"}
          >
            <input
              type="radio"
              id={option.value}
              name={name}
              value={option.value}
              ref={register}
            />
            <label>{option.label}</label>
          </div>
        ))}
      </fieldset>

      <FieldError {...{ error }} />
    </label>
  )
})

export default RadioGroupField
