import revueFont from "./Revue.svg"
import arialBlackFont from "./ArialBlack.svg"
import arialNovaFont from "./ArialNova.svg"
import comicSansFont from "./ComicSans.svg"
import handelGothicFont from "./HandelGothic.svg"
import humnstFont from "./Humnst777.svg"
import swiFont from "./Swis721.svg"

export default {
  arialBlackFont,
  arialNovaFont,
  comicSansFont,
  handelGothicFont,
  revueFont,
  swiFont,
  humnstFont,
}
