import * as React from "react"
import { useFormContext } from "react-hook-form"
import clsx from "clsx"
import FieldError from "./FieldError"
import FieldLabel from "./FieldLabel"

const ComponentSelectField = React.forwardRef((props, ref) => {
  const {
    label,
    name,
    options,
    information,
    className,
    buttonClassName,
    renderItem,
    allowNone,
  } = props
  const { errors, setValue, register, watch } = useFormContext()
  const value = watch(name)
  const error = errors?.[name]

  function renderDetails(option) {
    if (typeof option.render === "function") {
      return (
        <div className="flex flex-1 items-center justify-center flex-wrap">
          {option.render(value === option.value)}
        </div>
      )
    }

    if (typeof renderItem === "function") {
      return (
        <div className="flex flex-1 items-center justify-center flex-wrap">
          {renderItem(option, value === option.value)}
        </div>
      )
    }
    return null
  }

  return (
    <label className="block">
      <FieldLabel {...{ label, information }} />

      <div
        className={clsx(
          `mt-1`,
          className || "grid grid-cols-3 md:grid-cols-5 gap-2"
        )}
      >
        {options.map(option => (
          <button
            key={option.value}
            type="button"
            onClick={() => {
              setValue(
                name,
                option.value === value && allowNone ? null : option.value,
                { shouldValidate: true }
              )
            }}
            className={clsx(
              "outline-none focus:ring-blue-600 focus:rounded-none mt-1 p-8 py-4 break-words",
              value === option.value
                ? "bg-indigo-600 text-white"
                : "bg-indigo-100 text-indigo-600",
              buttonClassName
            )}
            style={{ hyphens: "auto" }}
          >
            <div className="flex flex-1 flex-col ">
              {renderDetails(option)}
              {option.label && (
                <div
                  className="font-semibold break-words mt-3"
                  style={{ hyphens: "auto" }}
                >
                  {option.label}
                </div>
              )}
            </div>
          </button>
        ))}
      </div>
      <FieldError {...{ error }} />
      <input ref={register()} name={name} className="hidden" />
    </label>
  )
})

export default ComponentSelectField
