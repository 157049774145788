import * as React from "react"
import { useForm, FormProvider } from "react-hook-form"
import InputField from "../InputField"
import FileInputField from "../FileInputField"
import ComponentSelectField from "../ComponentSelectField"
import SelectField from "../SelectField"

import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { useFormStepperContext } from "../FormStepper/FormStepperProvider"
import Button from "../Button"
import images from "../../images"
import PositionSelection, { BACK_POSITIONS } from "../PositionSelectionField"
import TextAreaField from "../TextAreaField"

const schema = yup.object().shape({
  names: yup.mixed().when("option", {
    is: "names",
    then: yup
      .mixed()
      .test(
        "fileCount",
        "Bitte wähle eine Datei aus",
        value => value?.length === 1
      ),
  }),
  material: yup.mixed().when("option", {
    is: "names",
    then: yup.string().required("Wähle ein Veredelungsverfahren aus"),
  }),
  color: yup.string().when("option", {
    is: "names",
    then: yup.string().required("Wähle eine Farb aus"),
  }),
  additionalDesigns: yup.array().of(
    yup.object().shape(
      {
        file: yup.mixed().when(["note"], {
          is: note => !note,
          then: yup
            .mixed()
            .required("Bitte Datei hochladen oder Beschreibung ausfüllen"),
        }),
        note: yup.string().when(["file"], {
          is: file => !file || file.length === 0,
          then: yup
            .string()
            .required("Bitte Datei hochladen oder Beschreibung ausfüllen"),
        }),
      },
      [["file", "note"]]
    )
  ),
})

export default function BackSideStep({ values }) {
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: values,
  })

  const backOption = methods.watch("option")

  const { setValues, next, previous } = useFormStepperContext() || {}

  function onNext(data) {
    setValues(data)
    next()
  }

  function renderSelectionForm() {
    return (
      <form onSubmit={methods.handleSubmit(onNext)}>
        <div className="grid grid-cols-1 gap-6">
          <ComponentSelectField
            name="option"
            label="Option"
            allowNone
            className="grid grid-cols-2 md:grid-cols-2 l:grid-cols-3 xl:grid-cols-3 gap-2"
            renderItem={option => (
              <img
                alt={`Swissbadge Pullover mit ${option.label}`}
                style={{ maxHeight: 300 }}
                src={option.image}
              />
            )}
            options={[
              {
                label: "Namensliste deines Zuges",
                value: "names",
                image: images.backNames,
              },
              {
                label: "Individuelles Design",
                value: "individual",
                image: images.backIndividual,
              },
            ]}
          />

          {backOption === "names" && (
            <>
              <SelectField
                name="material"
                label="Veredelungsverfahren"
                options={[
                  {
                    label: "Stick",
                    value: "STICK",
                  },
                  {
                    label: "Druck",
                    value: "DRUCK",
                  },
                ]}
              />
              <FileInputField
                fullWidth
                name="names"
                label="Namensliste deines Zuges"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                information={
                  <p>
                    Bitte{" "}
                    <a
                      rel="noreferrer"
                      href="https://docs.google.com/spreadsheets/d/1wpWcr2k3-2GvCQwUC6QQv1cErHNdRT3hMBpOXUSRcMs"
                      target="_blank"
                      className="underline text-indigo-600"
                    >
                      diese Excel-Vorlage
                    </a>{" "}
                    mit allen Angaben ausfüllen und mitschicken.
                  </p>
                }
              />
              <InputField
                fullWidth
                name="color"
                label="Farbe"
                placeholder="z.B. weiss"
              />
            </>
          )}

          {backOption && (
            <PositionSelection
              name="additionalDesigns"
              label={
                backOption === "individual"
                  ? "Individuelles Design"
                  : "Weitere individuelle Designs"
              }
              views={BACK_POSITIONS}
            />
          )}

          <TextAreaField name="notes" label="Bemerkungen" rows="3" />

          <div className="flex justify-between mt-8">
            <Button
              type="button"
              className="justify-self-end"
              label="Zurück"
              onClick={previous}
              variant="text"
            />

            <Button type="submit" className="justify-self-end" label="Weiter" />
          </div>
        </div>
      </form>
    )
  }

  return <FormProvider {...methods}>{renderSelectionForm()}</FormProvider>
}
