import * as React from "react"
import { useForm, FormProvider } from "react-hook-form"
import InputField from "../InputField"
import TextAreaField from "../TextAreaField"
import ComponentSelectField from "../ComponentSelectField"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"

import { useFormStepperContext } from "../FormStepper/FormStepperProvider"
import Button from "../Button"
import CheckboxField from "../CheckboxField"

const schema = yup.object().shape({
  addressType: yup.string().required(),
  militaryNumber: yup.mixed().when("addressType", {
    is: "feld",
    then: yup.string().required("Darf nicht leer sein"),
  }),
  name: yup.string().when("useContact", {
    is: val => val === false || val == null,
    then: yup.string().required("Darf nicht leer sein"),
  }),
  address: yup.string().when("useContact", {
    is: val => val === false || val == null,
    then: yup.string().required("Darf nicht leer sein"),
  }),
  city: yup
    .mixed()
    .when("addressType", {
      is: "kaserne",
      then: yup.string().required("Darf nicht leer sein"),
    })
    .when("addressType", {
      is: "private",
      then: yup.string().when("useContact", {
        is: false,
        then: yup.string().required("Darf nicht leer sein"),
      }),
    }),
  zip: yup
    .mixed()
    .when("addressType", {
      is: "kaserne",
      then: yup.string().required("Darf nicht leer sein"),
    })
    .when("addressType", {
      is: "private",
      then: yup.string().when("useContact", {
        is: false,
        then: yup.string().required("Darf nicht leer sein"),
      }),
    }),
})

export default function ShippingStep({ values }) {
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: values || {},
  })

  const type = methods.watch("addressType")
  const useContact = methods.watch("useContact")

  const { submit, previous, allValues } = useFormStepperContext()

  async function onSubmit(data) {
    const {
      useContact,
      name,
      addressType,
      address,
      zip,
      city,
      militaryNumber,
    } = data

    const { contact } = allValues

    const submitValues = {
      name: useContact ? `${contact.firstName} ${contact.lastName}` : name,
      address: useContact ? contact.address : address,
      ...(addressType === "feld"
        ? { zip: "Militär", city: militaryNumber }
        : {}),
      ...(addressType === "kaserne" ||
      (addressType === "private" && !useContact)
        ? { zip, city }
        : {}),
      ...(addressType === "private" && useContact
        ? { zip: contact.zip, city: contact.city }
        : {}),
    }

    submit(submitValues)
  }

  function renderAddressForm() {
    return (
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="grid grid-cols-1 gap-6">
          <ComponentSelectField
            name="addressType"
            label="Lieferadresse"
            className="grid grid-cols-3 md:grid-cols-3 gap-2"
            options={[
              {
                label: "Privat",
                value: "private",
              },
              {
                label: "Kasernenadresse",
                value: "kaserne",
              },
              {
                label: "Feldadresse",
                value: "feld",
              },
            ]}
          />

          {type === "private" && (
            <CheckboxField name="useContact" label="Wie Privatadresse" />
          )}

          {type && !useContact && (
            <InputField
              name="name"
              label={type === "private" ? "Name" : "Name (inkl. Grad)"}
              fullWidth
              placeholder={type === "private" ? "Hans Meier" : "Sdt Hans Meier"}
            />
          )}

          {type === "private" && !useContact && (
            <>
              <InputField
                name="address"
                label="Strasse & Nr."
                fullWidth
                placeholder="Bahnhofstrasse 1"
              />
              <InputField name="zip" label="PLZ" placeholder="8001" />
              <InputField name="city" label="Ort" placeholder="Zürich" />
            </>
          )}

          {type === "feld" && (
            <>
              <InputField
                name="address"
                label="Anschrift"
                fullWidth
                placeholder="Inf Kp 13/1"
              />
              <InputField
                name="militaryNumber"
                label="Militärleitzahl"
                fullWidth
                placeholder="61114"
                leading="Militär"
                inputClassName="pl-16"
              />
            </>
          )}

          {type === "kaserne" && (
            <>
              <TextAreaField
                name="address"
                label="Anschrift"
                fullWidth
                rows="3"
                placeholder={"Pz RS 23-1\nKp2, Zug 1\nKaserne"}
              />
              <InputField
                name="zip"
                label="PLZ"
                placeholder="3609"
                className="inline-block"
              />
              <InputField
                name="city"
                label="Ort"
                placeholder="Thun"
                fullWidth
              />
            </>
          )}

          <div className="flex justify-between mt-8">
            <Button
              type="button"
              className="justify-self-end"
              label="Zurück"
              onClick={previous}
              variant="text"
            />

            {type && (
              <Button
                type="submit"
                className="w-auto"
                label="Anfrage Absenden"
              />
            )}
          </div>
        </div>
      </form>
    )
  }

  return <FormProvider {...methods}>{renderAddressForm()}</FormProvider>
}
