import frontIndividual from "./front_individual.png"
import frontFunction from "./front_function.png"
import backNames from "./back_names.jpg"
import backIndividual from "./back_individual.jpg"
import backOutline from "./back_outline.png"
import frontOutline from "./front_outline.png"
import armeeSuisse from "./armee_suisse.png"
import hoodiesCover from "./hoodies_cover.jpg"
import hoodiesSide from "./hoodies_side.jpg"
import badgesCover from "./badges_cover.jpg"
import velcro from "./velcro.jpg"
import fonts from "./fonts"

export default {
  frontIndividual,
  frontFunction,
  backNames,
  backIndividual,
  backOutline,
  frontOutline,
  hoodiesCover,
  badgesCover,
  hoodiesSide,
  fonts,
  armeeSuisse,
  velcro,
}
