import * as React from "react"
import { useForm, FormProvider } from "react-hook-form"

import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { useFormStepperContext } from "../FormStepper/FormStepperProvider"
import Button from "../Button"
import images from "../../images"
import CheckboxField from "../CheckboxField"
import PositionSelection, { ARM_POSITIONS } from "../PositionSelectionField"
import TextAreaField from "../TextAreaField"

const schema = yup.object().shape({
  additionalDesigns: yup.array().of(
    yup.object().shape(
      {
        file: yup.mixed().when(["note"], {
          is: note => !note,
          then: yup
            .mixed()
            .required("Bitte Datei hochladen oder Beschreibung ausfüllen"),
        }),
        note: yup.string().when(["file"], {
          is: file => (!file || file.length === 0),
          then: yup
            .string()
            .required("Bitte Datei hochladen oder Beschreibung ausfüllen"),
        }),
      },
      [["file", "note"]]
    )
  ),
})

export default function ArmStep({ values }) {
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: values || { velcroPatch: true, armeeSuisse: true },
  })

  const hasSticker = methods.watch("armeeSuisse")
  const hasVelcro = methods.watch("velcroPatch")

  const FILTERED_ARM_POSITIONS = React.useMemo(
    () => [
      ...(hasSticker ? ["ARM_TOP_LEFT", "ARM_BACK_TOP_LEFT"] : []),
      ...(hasVelcro ? ["ARM_TOP_RIGHT", "ARM_BACK_TOP_RIGHT"] : []),
    ],
    [hasVelcro, hasSticker]
  )

  const { setValues, next, previous } = useFormStepperContext() || {}

  function onNext(data) {
    setValues(data)
    next()
  }

  function renderSelectionForm() {
    return (
      <form onSubmit={methods.handleSubmit(onNext)}>
        <div className="grid grid-cols-1 gap-6">
          <CheckboxField
            name="armeeSuisse"
            render={() => (
              <div className="flex space-x-4 items-center">
                <img
                  alt="Pullover mit Armee Suisse Sticker"
                  src={images.armeeSuisse}
                  className="w-24"
                ></img>
                <div className="flex-1">
                  <span className="text-l font-bold">
                    Stickerei „Armee Suisse“
                  </span>
                  <br />
                  <span className="">
                    Original wie auf dem TAZ, links auf Schulterhöhe
                  </span>
                </div>
              </div>
            )}
          />
          <CheckboxField
            name="velcroPatch"
            className="-mt-4"
            render={() => (
              <div className="flex space-x-4 items-center">
                <img
                  alt="Pullover mit Velcro Patch"
                  src={images.velcro}
                  className="w-24"
                ></img>
                <div className="flex-1">
                  <span className="text-l font-bold">
                    Klett für Swissbadge
                    <span className="font-light"> (ohne Swissbadge)</span>
                  </span>
                  <br />
                  <span className="">
                    Original Velcro-Klettverschluss für Abzeichen, rechts auf
                    Schulterhöhe
                  </span>
                </div>
              </div>
            )}
          />

          <PositionSelection
            name="additionalDesigns"
            label="Individuelles Design"
            views={ARM_POSITIONS}
            filteredPositions={FILTERED_ARM_POSITIONS}
          />

          <TextAreaField name="notes" label="Bemerkungen" rows="3" />

          <div className="flex justify-between mt-8">
            <Button
              type="button"
              className="justify-self-end"
              label="Zurück"
              onClick={previous}
              variant="text"
            />

            <Button type="submit" className="justify-self-end" label="Weiter" />
          </div>
        </div>
      </form>
    )
  }

  return <FormProvider {...methods}>{renderSelectionForm()}</FormProvider>
}
