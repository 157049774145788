import * as React from "react"
import { useFormContext } from "react-hook-form"
import clsx from "clsx"
import FieldError from "./FieldError"
import FieldLabel from "./FieldLabel"

const CheckboxField = React.forwardRef((props, ref) => {
  const { label, name, className, render, children } = props
  const { errors, register } = useFormContext()
  const error = errors?.[name]

  function renderChildren() {
    if (typeof render === "function") {
      return render()
    }
    if (children) {
      return children
    }
    return <div>{label}</div>
  }

  return (
    <label className="block cursor-pointer">
      {label && (children != null || typeof render === "function") ? (
        <FieldLabel {...{ label }} />
      ) : null}
      <div
        className={clsx(
          `mt-1 flex p-4 items-center space-x-3 bg-indigo-100`,
          className
        )}
      >
        <input type="checkbox" ref={register()} name={name} />
        <div className="flex-1">{renderChildren()}</div>
      </div>
      <FieldError {...{ error }} />
    </label>
  )
})

export default CheckboxField
