const API_ROOT =
  process.env.NODE_ENV === "production"
    ? "https://europe-west3-swissbadge.cloudfunctions.net/api"
    : "http://localhost:5001/swissbadge/europe-west3/api"

function createMultiPartRequest(values, fileList = []) {
  const fields = {}

  if (values instanceof File) {
    fileList.push(values)
    return values.name
  }
  if (values instanceof FileList) {
    Array.from(values).forEach(file => fileList.push(file))
    const fileNameList = Array.from(values).map(val => val.name)
    return fileNameList.length === 1 ? fileNameList[0] : fileNameList
  }

  if (typeof values !== "object") {
    return values
  }

  if (Array.isArray(values)) {
    return values
      .map(val => createMultiPartRequest(val, fileList))
      .filter(val => val != null)
  }
  Object.entries(values).forEach(([key, value]) => {
    const mappedValue = createMultiPartRequest(value, fileList)
    if (mappedValue != null) {
      fields[key] = mappedValue
    }
  })

  return fields
}

function createFormData(values) {
  const fileList = []
  const fields = createMultiPartRequest(values, fileList)

  const formData = new FormData()
  fileList.forEach(file => {
    formData.append(file.name, file)
  })

  formData.append("data", JSON.stringify(fields))
  return formData
}

async function createHoodiesOrder(values) {
  const formData = createFormData(values)
  await fetch(`${API_ROOT}/hoodies`, {
    mode: "no-cors",
    method: "POST",
    body: formData,
  })
}

export default {
  createHoodiesOrder,
}
