import * as React from "react"
import { useForm, FormProvider } from "react-hook-form"
import InputField from "../InputField"
import ComponentSelectField from "../ComponentSelectField"

import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { useFormStepperContext } from "../FormStepper/FormStepperProvider"
import Button from "../Button"
import images from "../../images"
import FieldLabel from "../FieldLabel"

function validateAmount(...args) {
  const amount =
    args.reduce((acc, cur) => (typeof cur === "number" ? acc + cur : acc), 0) +
    (parseInt(args[args.length - 1].value, 10) || 0)
  return amount < 5
    ? args[args.length - 2].min(5, "Mind. Bestellmenge ist 5 Stück")
    : undefined
}

const schema = yup.object().shape({
  color: yup
    .string()
    .required("Wähle eine Farbe aus")
    .oneOf(
      [...Array(46)].map((_, idx) => (idx + 1).toString()),
      "Wähle eine gültige Farb-Zahl aus der Farbliste aus"
    ),
  font: yup.string().required("Wähle eine Schrift aus"),
  sizes: yup.object().shape({
    s: yup
      .number()
      .when(["m", "l", "xl", "xxl"], validateAmount)
      .min(0, "Muss grösser oder gleich 0 sein"),
    m: yup.number().min(0, "Muss grösser oder gleich 0 sein"),
    l: yup.number().min(0, "Muss grösser oder gleich 0 sein"),
    xl: yup.number().min(0, "Muss grösser oder gleich 0 sein"),
    xxl: yup.number().min(0, "Muss grösser oder gleich 0 sein"),
  }),
})

export default function GeneralStep({ values }) {
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: values || { sizes: { s: 0, m: 0, l: 0, xl: 0, xxl: 0 } },
  })

  const { setValues, next } = useFormStepperContext() || {}

  function onNext(data) {
    setValues(data)
    next()
  }

  const sizes = methods.watch("sizes")

  React.useEffect(() => {
    if (methods.errors?.sizes != null) {
      methods.trigger("sizes")
    }
  }, [sizes, methods])

  function renderSelectionForm() {
    return (
      <form onSubmit={methods.handleSubmit(onNext)}>
        <div className="grid grid-cols-1 gap-6">
          <div>
            <FieldLabel
              label="Anzahl pro Grösse"
              information="Mindestmenge sind 5 Stück"
            ></FieldLabel>
            <div className="gap-1 flex flex-wrap">
              <InputField
                inputClassName="w-28"
                name="sizes.s"
                placeholder="S"
                type="number"
                label="S"
                min="0"
              ></InputField>
              <InputField
                inputClassName="w-28"
                name="sizes.m"
                placeholder="M"
                type="number"
                label="M"
                min="0"
              ></InputField>
              <InputField
                inputClassName="w-28"
                name="sizes.l"
                placeholder="L"
                type="number"
                label="L"
                min="0"
              ></InputField>
              <InputField
                inputClassName="w-28"
                name="sizes.xl"
                placeholder="XL"
                type="number"
                label="XL"
                min="0"
              ></InputField>
              <InputField
                inputClassName="w-28"
                name="sizes.xxl"
                placeholder="XXL"
                label="XXL"
                type="number"
                min="0"
              ></InputField>
            </div>
          </div>

          <InputField
            name="color"
            type="text"
            placeholder=""
            information={
              <p>
                Wähle eine Farbe aus der{" "}
                <a
                  rel="noreferrer"
                  href="https://drive.google.com/file/d/1aEs9RIHzsOVuqJc58dMnPnFb_do8xyNz/view"
                  target="_blank"
                  className="underline text-indigo-600"
                >
                  Farbliste
                </a>{" "}
                aus und füge die entsprechende Farb-Zahl ein.
              </p>
            }
            label="Farbe"
          ></InputField>

          <ComponentSelectField
            name="font"
            label="Schrift"
            information="Die Schriftart wird für Text (z.B. Namen auf dem Rücken) verwendet"
            className="grid grid-cols-2 md:grid-cols-3 gap-2"
            renderItem={option => (
              <img
                alt={`Vorschau der Schrift ${option.label}`}
                height="100%"
                src={option.image}
              />
            )}
            options={[
              {
                value: "Arial Black",
                image: images.fonts.arialBlackFont,
              },
              {
                value: "Arial Nova",
                image: images.fonts.arialNovaFont,
              },
              {
                value: "Comic Sans",
                image: images.fonts.comicSansFont,
              },
              {
                value: "Handel Gothic",
                image: images.fonts.handelGothicFont,
              },
              {
                value: "Revue",
                image: images.fonts.revueFont,
              },
              {
                value: "Swis721",
                image: images.fonts.swiFont,
              },
              {
                value: "Humnst777",
                image: images.fonts.humnstFont,
              },
            ]}
          />

          <Button type="submit" className="justify-self-end" label="Weiter" />
        </div>
      </form>
    )
  }

  return <FormProvider {...methods}>{renderSelectionForm()}</FormProvider>
}
