import * as React from "react"
import { FiChevronRight } from "@react-icons/all-files/fi/FiChevronRight"
import clsx from "clsx"
import LoadingOverlay from "../LoadingOverlay"
import { navigate } from "gatsby"
import { Router, useLocation } from "@reach/router"

const initialContext = {
  allValues: {},
  setValues: () => undefined,
  next: () => undefined,
  currentStep: 0,
  submit: () => undefined,
}

const FormStepperContext = React.createContext(initialContext)

function FormStepperProvider({ children, steps, onSubmit, name, path }) {
  const [allValues, setAllValues] = React.useState({})
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const location = useLocation()

  const currentStep = steps.findIndex(step =>
    location.pathname.includes(`${path}/${step.path}`)
  )

  const completedSteps = steps.map(
    step => Object.keys(allValues).includes(step.path) || step.isSkippable
  )
  const lastCompletedStep =
    completedSteps.reduce((acc, cur, idx) => (cur ? idx : acc), -1) + 1

  React.useEffect(() => {
    if (
      currentStep < 0 ||
      currentStep >= steps.length ||
      currentStep > lastCompletedStep
    ) {
      navigate(`${path}/${steps[lastCompletedStep]?.path}`)
    }
  }, [currentStep, lastCompletedStep, path, steps])

  function setValues(values) {
    setAllValues(preValues => ({
      ...preValues,
      [steps[currentStep]?.path]: values,
    }))
  }

  function next() {
    if (currentStep < steps.length - 1) {
      navigate(`${path}/${steps[currentStep + 1]?.path}`)
    }
  }

  const submit = React.useCallback(
    async submitValues => {
      if (typeof onSubmit === "function") {
        setIsSubmitting(true)
        try {
          await onSubmit({
            ...allValues,
            [steps[currentStep].path]: submitValues,
          })
        } catch (e) {
          throw e
          //
        } finally {
          setIsSubmitting(false)
        }
      }
    },
    [allValues, onSubmit, steps, currentStep]
  )

  function previous() {
    if (currentStep > 0) {
      navigate(`${path}/${steps[currentStep - 1]?.path}`)
    }
  }

  const hasNextStep = React.useMemo(() => currentStep < steps.length - 1, [
    currentStep,
    steps,
  ])

  const hasPreviousStep = React.useMemo(() => currentStep > 0, [currentStep])

  return (
    <>
      {isSubmitting && <LoadingOverlay />}
      <FormStepperContext.Provider
        value={{
          allValues,
          setValues,
          next,
          currentStep,
          hasNextStep,
          hasPreviousStep,
          previous,
          submit,
        }}
      >
        <div className="mb-8 after:none">
          <span className="text-3xl font-bold">{`${name}${
            steps[currentStep] ? `: ${steps[currentStep].label}` : ""
          }`}</span>
          <div className="mt-2 flex -ml-2 items-center flex-wrap">
            {steps.map((step, idx) => (
              <div className="flex mt-2 ml-2 flex-nowrap" key={idx.toString()}>
                <span
                  className={clsx(
                    "text-xs font-semibold uppercase ",
                    idx === currentStep ? "text-indigo-300" : "text-gray-400"
                  )}
                >
                  {step.label}
                </span>
                {idx !== steps.length - 1 && (
                  <FiChevronRight className="text-gray-400 ml-2" />
                )}
              </div>
            ))}
          </div>
        </div>
        {children}
        <Router basepath={path}>
          {steps.map(({ component: Comp, path }, index) =>
            index <= lastCompletedStep ? (
              <Comp key={path} path={`/${path}`} values={allValues[path]} />
            ) : null
          )}
        </Router>
      </FormStepperContext.Provider>
    </>
  )
}

export const useFormStepperContext = () => React.useContext(FormStepperContext)

export default FormStepperProvider
